<template>
  <span class="spaceList">
    <div class="imagem" v-if="type === 'course'">
        <img v-if="content.cover.cdn_url !== null" :src="content.cover.cdn_url" :alt="content.cover.title">
        <img v-else src="@/assets/icons/logo-fake.svg" alt="img">
    </div>
    <div class="imagem" v-if="type === 'module'">
        <img v-if="content.cover !== null" :src="content.cover.cdn_url" :alt="content.cover.title">
        <img v-else :src="content.course.cover.cdn_url" :alt="content.course.cover.title">
    </div>
    <div class="imagem" v-if="type === 'lesson'">
        <!-- <img v-if="content.thumb !== null" :src="content.thumb" alt="thumb"> -->
        <img :src="content.course.cover.cdn_url" :alt="content.course.cover.title">
    </div>
    
    <div class="text">
        <p :class="{'selectedText':itemid === content.id && typeSelectedItem.title === content.title}">{{ content.title }}</p>
        <div v-if="itemid === content.id && typeSelectedItem.title === content.title" class="itemSelected">
            <p>Abrir</p>
            <svg style="width: 18px;" width="21" height="21" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
                <rect x="20.0641" y="20.3643" width="20" height="20" rx="5" transform="rotate(180 20.0641 20.3643)" fill="#333333"/>
                <path d="M8.93909 9.61426L7.06409 11.4893M7.06409 11.4893L8.93909 13.3643M7.06409 11.4893H11.5641C11.9619 11.4893 12.3434 11.3312 12.6247 11.0499C12.9061 10.7686 13.0641 10.3871 13.0641 9.98926V7.36426" stroke="white" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
        </div>
    </div>
  </span>
</template>

<script>

export default {
    name:'CourseHome',
    props:['typeSelectedItem', 'itemid', 'content', 'permission', 'ratingHome', 'type', 'isMobile'],
    methods:{
      
    }
}
</script>

<style lang="scss" scoped>
.spaceList{
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 6fr;
    padding: 0 20px;
}

.text{
    display: flex;
    justify-content: space-between;
    p{
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        color: #81858E;
        margin: 0;
        display: flex;
        align-self: center;
    }
    .selectedText{
        font-weight: 600 !important;
        color: #333333 !important;
    }
}

.itemSelected{
    display: flex;
    align-items: center;
    p{
        font-family: Montserrat;
        font-style: normal;
        font-weight: normal;
        font-size: 10px;
        color: #333333;
        margin-right: 4px;
    }
}

.imagem {
    height: 40px;
    overflow: hidden;
    width: 40px;
    margin-right: 5px;
    img{
        border-radius: 3px;
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}
</style>