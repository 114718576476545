<template>
    <b-modal id="modal-my-courses" hide-footer size="xl">
        <div class="spaceModal">
            <div class="titleModal">{{ $t('modal_my_courses.title') }}</div>
            <div class="spaceListCourses">
                <div class="spacePainels">
                    <br>
                    <div v-if="!isMobile">
                        <div class="gridHeader">
                            <p></p>
                            <p>{{ $t('modal_my_courses.curso') }}</p>
                            <p>{{ $t('modal_my_courses.painel') }}</p>
                            <p>{{ $t('modal_my_courses.tipo') }}</p>
                            <p>{{ $t('modal_my_courses.validade') }}</p>
                            <p>{{ $t('modal_my_courses.progresso') }}</p>
                            <p></p>                              
                        </div>
                        <div class="gridBody" v-for="course in courses" :key="course.id">
                            <div class="imgCourse" v-if="course.cover !== null">
                                <img :src="course.cover.cdn_url" alt="img">
                            </div>
                            <div class="imgCourse" v-else>
                            </div>
                            <p>{{ course.title }}</p>
                            <p>{{ course.site.name }}</p>
                            <p v-if="course.type_access === 'course_class'">{{ $t('modal_my_courses.curso') }}</p>
                            <p v-if="course.type_access === 'subscription'">{{ $t('menu.assinatura') }}</p>
                            <p v-if="course.liberated_until !== null && course.type_access !== 'subscription'">{{ course.liberated_until | moment("DD/MM/YYYY HH:mm")}}</p>
                            <p v-else-if="course.liberated_until === null">{{ $t('modal_my_courses.ilimitado') }}</p>
                            <b-progress :value="course.percentage_complete" show-value :max="100" class="spaceProgress"></b-progress>
                            <div class="divButton" v-if="currentSiteId === course.site.id">
                                <router-link :to="'/curso/'+course.id" class="remove-underline">
                                    <button>{{ $t('modal_my_courses.acessar') }} {{ $t('modal_my_courses.curso') }}</button>
                                </router-link>
                            </div>
                            <div class="divButton" v-else>
                                <a @click="redirectTo(course.site.id, course.id)" class="remove-underline">
                                    <button>{{ $t('modal_my_courses.acessar') }} {{ $t('modal_my_courses.curso') }}</button>
                                </a>
                            </div>
                        </div>
                    </div>
                    <div v-else>
                        <div class="gridHeaderMobile">
                            <p>{{ $t('modal_my_courses.curso') }}</p>
                            <p></p>                              
                        </div>
                        <div class="gridBodyMobile" v-for="course in courses" :key="course.id">
                            <p>{{ course.title }}</p>
                            <div class="divButton" v-if="currentSiteId === course.site.id">
                                <router-link :to="'/curso/'+course.id" class="remove-underline">
                                    <button>{{ $t('modal_my_courses.acessar') }} {{ $t('modal_my_courses.curso') }}</button>
                                </router-link>
                            </div>
                            <div class="divButton" v-else>
                                <a @click="redirectTo(course.site.id, course.id)" class="remove-underline">
                                    <button>{{ $t('modal_my_courses.acessar') }} {{ $t('modal_my_courses.curso') }}</button>
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </b-modal>
</template>
<script>
import MemberService from "@/services/resources/MemberService";
const serviceMember = MemberService.build();

export default {
    components: {
    },
    props: ["allpainels", "currentSiteId", "courses"],
    data(){
        return{
            client:{
                width: 0
            },
        }
    },
    created(){
        window.addEventListener('resize', this.handleResize)
        this.handleResize();
    },
    computed: {
        isMobile() {
            return this.client.width <= 576;
        },
    },
    methods:{
        handleResize() {
            this.client.width = window.outerWidth;
        },
        redirectTo(painel_id, curso_id){
            let painels = this.allpainels;
            //console.log(painels);
            var array = [];
            for (let i = 0; i < painels.length; i++) {
                const painel = painels[i];
                if(painel_id === painel.id){
                    array.push(painel);
                }
            }
            //console.log(array);
            array[0].course_id = curso_id;
            this.$store.dispatch("blogSelected", array[0]);
        },
    },
}
</script>
<style lang="scss">
    #modal-my-courses{
        .textSlug{
            font-family: Montserrat;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            color: #000000;
        }
        .remove-underline {
            text-decoration: none;
            cursor: pointer;
        }
        .spaceListCourses {
            overflow: auto;
            height: 73vh;
        }
        .gridHeader{
            display: grid;
            grid-template-columns: 1fr 3fr 2fr 1fr 2fr 2fr 2fr;
            p{
                font-family: Montserrat;
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                color: var(--fontcolor);
            }
        }
        .gridHeaderMobile{
            display: grid;
            grid-template-columns: 3fr 1fr;
            p{
                font-family: Montserrat;
                font-style: normal;
                font-weight: 600;
                font-size: 16px;
                color: var(--fontcolor);
            }
        }
        .gridBodyMobile{
            display: grid;
            grid-template-columns: 3fr 1fr;
            min-height: 60px;
            align-items: center;
            .imgCourse{
                border-radius: 2px;
                overflow: hidden;
                width: 55px;
                margin-bottom: 10px;            
            }
            .divButton{
                padding: 0px 25px;
                margin-top: -10px;
                button{
                    background: #2133d20d;
                    box-sizing: border-box;
                    border-radius: 5px;
                    font-family: Montserrat;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 14px;
                    color: #2133D2;
                    height: 47px;
                    border:none;
                }
            }
            p{
                font-family: Montserrat;
                font-style: normal;
                font-weight: 600;
                font-size: 12px;
                color: var(--fontcolor);
            }
        }
        .gridBody{
            display: grid;
            grid-template-columns: 1fr 3fr 2fr 1fr 2fr 2fr 2fr;
            height: 50px;
            .imgCourse{
                border-radius: 2px;
                overflow: hidden;
                width: 55px;
                margin-bottom: 10px;            
            }
            .divButton{
                padding: 0px 25px;
                margin-top: -10px;
                button{
                    background: #2133d20d;
                    box-sizing: border-box;
                    border-radius: 5px;
                    font-family: Montserrat;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 14px;
                    color: #2133D2;
                    height: 35px;
                    border:none;
                }
            }
            p{
                font-family: Montserrat;
                font-style: normal;
                font-weight: 600;
                font-size: 12px;
                color: var(--fontcolor);
            }
        }
        .header{
            width: 100%;
            display: flex;
            justify-content: flex-end;
        }
        .modal-header {
            border: none !important;
        }
        .spaceModal{
            padding: 5px ​30px 0px 30px;
            margin-top: -20px;
            .titleModal{
                font-family: Montserrat;
                font-style: normal;
                font-weight: 800;
                font-size: 18px;
                letter-spacing: 0.2px;
                color: var(--fontcolor);
            }
        }
    }
</style>