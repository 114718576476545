<template>
  <b-modal id="modal-new-painel" hide-footer size="lg">
    <div class="spaceModal">
      <div class="titleModal">{{ $t("modal_new_panel.create_project") }}</div>
      <div class="spaceSelectPainel">
        <b-form @submit.stop.prevent="saveProject">
          <div class="spacePainels">
            <div class="spaceInputs">
              <b-form-group
                :label="$t('modal_new_panel.label_project_name')"
                label-for="name"
                id="step4"
              >
                <b-form-input
                  name="nameProject"
                  id="nameProject"
                  v-model="nameProject"
                  v-validate="{ required: true }"
                  :placeholder="$t('modal_new_panel.placeholder_project_name')"
                ></b-form-input>
                <b-form-invalid-feedback :state="!errors.has('nameProject')">
                  {{ $t("modal_new_panel.feedback_menssage_project_name") }}
                </b-form-invalid-feedback>
              </b-form-group>
            </div>
            <div class="spaceInputs">
              <b-form-group
                :label="$t('modal_new_panel.label_project_slug')"
                label-for="name"
                id="step4"
                class="flex"
              >
                  <b-input-group size="md" append=".greenn.club">
                    <b-form-input
                      name="slugProject"
                      id="slugProject"
                      style="width: 70%"
                      v-validate="{
                        required: true,
                        regex: /^[a-z\-0-9]{6,30}$/i,
                      }"
                      v-model="slugProject"
                      :placeholder="
                        $t('modal_new_panel.placeholder_project_slug')
                      "
                    ></b-form-input>
                  </b-input-group>
                <b-form-invalid-feedback :state="!errors.has('slugProject')">
                  {{ $t("modal_new_panel.feedback_menssage_project_slug") }}
                </b-form-invalid-feedback>
              </b-form-group>
            </div>
            <div class="spaceInputs">
              <b-form-group
                :label="$t('modal_new_panel.label_project_description')"
                label-for="resumo"
              >
                <b-form-textarea
                  id="textarea"
                  v-model="descricaoProject"
                  :placeholder="
                    $t('modal_new_panel.placeholder_project_description')
                  "
                  rows="3"
                  max-rows="6"
                ></b-form-textarea>
              </b-form-group>
            </div>
            <div class="spaceInputs">
              <b-form-group
                label-for="resumo"
              >
                <b-form-checkbox
                  id="checkbox-1"
                  v-model="statusDemo"
                  name="checkbox-1"
                  :value="true"
                  :unchecked-value="false"
                >
                {{$t('modal_new_panel.label_project_demo')}}
                </b-form-checkbox>
              </b-form-group>
            </div>
          </div>
          <div class="header">
            <b-button class="newPainel" type="submit" variant="primary">
              {{ $t("modal_new_panel.save_project") }}
            </b-button>
          </div>
        </b-form>
      </div>
    </div>
  </b-modal>
</template>
<script>
import notify from "@/services/libs/notificacao";
import axios from "axios";
import Cookies from "js-cookie";

export default {
  props: ["type"],
  data() {
    return {
      nameProject: "",
      descricaoProject: "",
      statusDemo: false,
      courseName: "",
      descricaoCourse: "",
      slugProject: "",
    };
  },
  methods: {
    saveProject() {
      this.$validator.validateAll().then(result => {
        if (result) {
          var data = "";
          if (this.statusDemo) {
            data = {
              site_name: this.nameProject,
              site_description: this.descricaoProject,
              subdomain: this.slugProject,
              create_demo: true,
            };
          } else {
            data = {
              site_name: this.nameProject,
              site_description: this.descricaoProject,
              subdomain: this.slugProject,
            };
          }
          var that = this;
          this.$root.$emit("loadOn");
          axios
            .post(process.env.VUE_APP_API_HOST + `/site`, data, {
              headers: {
                Authorization: Cookies.get("memberAuth"),
              },
            })
            .then(function (resp) {
              var newsite = resp.data;
              newsite.domains = [];
              newsite.custom_fields_filled = true;
              notify("sucesso", "Painel criado com Sucesso!");
              setTimeout(() => {
                that.$root.$emit("bv::hide::modal", "modal-new-painel", "#btnShow");
                that.$store.dispatch("blogSelected", newsite);
              }, 3000);
              that.$root.$emit("loadOff");
            })
            .catch((err) => {
              var error = err.response.data;
              var msg = "";
              for (var indice in error) {
                msg += error[indice][0] + "<br>";
              }
              if (msg !== "") {
                notify("erro", msg);
              }
              that.$root.$emit("loadOff");
            });
        } else {
          notify("erro", "Confira os campos do formulário");
        }
      });
    },
  },
};
</script>

<style lang="scss">
#modal-new-painel {
  input.form-control.input-group.has-error {
    border: 1px solid #a94442 !important;
    box-shadow: inset 0 1px 1px #00000014, 0 0 6px #ce8483 !important;
  }
  input.form-control.input-group.has-success {
    border: 1px solid #2b542c !important;
    box-shadow: inset 0 1px 1px #00000014, 0 0 6px #67b168 !important;
  }
  .textSlug {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    color: var(--fontcolor);
    margin-top: 12px;
    margin-left: 4px;
  }
  .spaceInputs {
    margin-bottom: 30px;
    select {
      border: 1px solid var(--bordercolor);
      box-sizing: border-box;
      border-radius: 5px;
      height: 55px;
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      color: #333;
      overflow: hidden !important;
      resize: none !important;
    }
    input,
    textarea {
      border: 1px solid var(--bordercolor);
      box-sizing: border-box;
      border-radius: 5px;
      height: 55px;
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      color: #333;
      overflow: hidden !important;
      resize: none !important;
      background: white;
    }
    input:hover,
    select:hover,
    textarea:hover,
    input:focus,
    select:focus,
    textarea:focus {
      border: 1px solid var(--maincolor);
      box-shadow: 0px 4px 10px rgba(3, 148, 105, 0.03);
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      color: #333;
    }
    label {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      color: var(--fontcolor);
    }
  }
  .header {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }
  .newPainel {
    background: var(--maincolor);
    border: 1px solid var(--maincolor);
    box-sizing: border-box;
    box-shadow: 0px 4px 10px rgba(3, 148, 105, 0.03);
    border-radius: 5px;
    height: 55px;
    width: 250px;
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    letter-spacing: 0.2px;
    color: #ffffff;
    padding: 0px 15px;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .modal-header {
    border: none !important;
  }
  .spaceModal {
    padding: 5px 30px 0px 30px;
    margin-top: -20px;
    .logout {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      color: #ff0c37;
      margin-top: 10px;
      cursor: pointer;
    }
    .logout2 {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 600;
      font-size: 14px;
      color: var(--maincolor);
      margin-top: 10px;
      cursor: pointer;
    }
    .titleModal {
      font-family: Montserrat;
      font-style: normal;
      font-weight: 800;
      font-size: 18px;
      letter-spacing: 0.2px;
      color: var(--fontcolor);
    }
    .spaceSelectPainel {
      background: var(--backgroundcolor);
      border-radius: 5px;
      padding: 20px 5px;
      .lineDiv {
        height: 0.5px;
        background: #ededf0;
        width: 100%;
        margin-bottom: 25px;
        margin-top: 25px;
      }
      .flexData {
        display: flex;
        margin-bottom: 20px;
        cursor: pointer;
        .pointer {
          background: rgba(0, 214, 228, 0.1);
          border: 1px solid #00d6e4;
          box-sizing: border-box;
          width: 40px;
          height: 40px;
          border-radius: 100%;
        }
        .title {
          font-family: Montserrat;
          font-style: normal;
          font-weight: 600;
          font-size: 14px;
          color: var(--fontcolor);
          margin-left: 20px;
          line-height: 40px;
        }
      }
    }
  }
}
</style>
