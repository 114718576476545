<template>
  <b-modal id="modal-plans" @show="getPlans"
      @hidden="getPlans" size="xl" hide-footer>
    <h1>Meus Planos</h1>
    <b-progress class="plans" :max="100">
      <b-progress-bar :value="percentage"></b-progress-bar>
    </b-progress>
    <br />
    <Planos :plans="plans" />
  </b-modal>
</template>
<script>
import Planos from "./Planos.vue";
import PlansService from "@/services/resources/PlansService";
const servicePlans = PlansService.build();

export default {
  props: ["percentage"],
  components: {
    Planos,
  },
  mounted(){
    this.getPlans();
  },
  methods:{
    getPlans() {
      servicePlans
        .read("")
        .then((resp) => {
          this.plans = resp;
        })
        .catch((err) => {
          //console.log(err);
        });
    },
  },
  data() {
    return {
      plans: []
    };
  },
};
</script>
<style lang="scss">
#modal-plans {
  header {
    border: none;
  }
  label {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
    color: var(--fontcolor2);
  }
  h1 {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 800;
    font-size: 24px;
    color: var(--fontcolor);
  }
}
</style>